import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Spinner, Card, Button, Modal } from 'react-bootstrap'
import { getData, deleteData } from '../../utilities/dataProvider'
import AuthContext from '../../Store/AuthContext'
import configurationData from '../../configuration.json'

export default function ConfigList() {
  const authCTX = useContext(AuthContext)
  const history = useHistory()
  const [configList, setConfigList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showModalForDelete, setShowModalForDelete] = useState(false)
  const [currentSelectedConfigName, setCurrentSelectedConfigName] = useState(
    null,
  )
  const [currentSelectedConfigID, setCurrentSelectedConfigID] = useState(null)
  const [currentSelectedConfigEnv, setCurrentSelectedConfigEnv] = useState(null)

  useEffect(() => {
    const manageConfigList = async () => {
      let conlist = await getConfigList()
      setConfigList(conlist)
      setIsLoading(false)
    }
    manageConfigList()
  }, [])

  const getConfigList = async () => {
    try {
      if (authCTX.userId === null) return []
      const GETCONFIGSURL = `${configurationData.API_BASE_URL}/config/getall`
      let header = {
        token: authCTX.token,
        userId: authCTX.userId,
      }
      let resp = await getData(GETCONFIGSURL, header)
      return resp.data ?? []
    } catch (error) {
      return []
    }
  }

  const deleteConfig = async () => {
    try {
      const DELETECONFIGSURL = `${configurationData.API_BASE_URL}/config/manage`
      let deleteRequest = {
        id: currentSelectedConfigID,
        environment: currentSelectedConfigEnv,
      }
      let header = {
        token: authCTX.token,
        userId: authCTX.userId,
      }
      await deleteData(DELETECONFIGSURL, deleteRequest, header)
      window.location.reload()
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleManageDeleteConfig = (id, name,env) => {
    setCurrentSelectedConfigID(id)
    setCurrentSelectedConfigName(name)
    setCurrentSelectedConfigEnv(env)
    handleShowModal()
  }

  const handleViewConfig = (id, env) => {
    history.push(`/manage/${id}/${env}`)
  }
  const handleConfirmDeleteConfig = async () => {
    handleCloseModal()
    setIsLoading(true)
    await deleteConfig()
  }
  const handleCloseModal = () => {
    setShowModalForDelete(false)
  }
  const handleShowModal = () => {
    setShowModalForDelete(true)
  }
  return (
    <Row>
      <Col>
        {isLoading && (
          <Row>
            <Modal
              show={isLoading}
              backdrop="static"
              keyboard={false}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body>
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="secondary" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
                <Spinner animation="grow" variant="light" />
                <Spinner animation="grow" variant="dark" />
              </Modal.Body>
            </Modal>
          </Row>
        )}
        {!isLoading && (
          <Row>
            <Col>
              {configList && configList.length === 0 && (
                <h4>No Record Exist</h4>
              )}
              {configList &&
                configList.length > 0 &&
                configList.map((item, index) => (
                  <Card
                    key={index}
                    border="info"
                    bg="secondary"
                    text="white"
                    style={{
                      width: '17rem',
                      margin: '2rem 2rem 2rem 0rem',
                      display: 'inline-block',
                    }}
                    className="mb-2"
                  >
                    <Card.Header>
                      <h4>{item.name}</h4>
                    </Card.Header>
                    <Card.Body>
                      <p>Environment : {item.environment}</p>
                    </Card.Body>
                    <Card.Footer>
                      <Button
                        variant="warning"
                        onClick={() =>
                          handleViewConfig(item.id, item.environment)
                        }
                      >
                        View
                      </Button>{' '}
                      <Button
                        variant="danger"
                        onClick={() =>
                          handleManageDeleteConfig(
                            item.id,
                            item.name,
                            item.environment,
                          )
                        }
                      >
                        Delete
                      </Button>
                      {'  '}
                    </Card.Footer>
                  </Card>
                ))}
            </Col>
          </Row>
        )}
        <Row>
          <Modal
            show={showModalForDelete}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header>
              <Modal.Title>
                Confirm Config Delete - <b>{currentSelectedConfigName}</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this config ?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleConfirmDeleteConfig}>
                Proceed
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Col>
    </Row>
  )
}
