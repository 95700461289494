import { Button, Card, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SignalList from '../components/Signal/SignalList'

export default function Signals() {
  const history = useHistory()
  const handleNavigateCreate = () => {
    history.push('/signals/add')
  }
  return (
    <Row>
      <Col>
        <Card border="info" style={{ marginTop: '10px' }}>
          <Card.Body>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={handleNavigateCreate}
                  size="lg"
                  block
                >
                  Add Signal
                </Button>
                <br />
                <br />
              </Col>
            </Row>
            <Row>
              <SignalList></SignalList>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
