import { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import MainNavigation from './MainNavigation'

const Layout = (props) => {
  return (
    <Fragment>
      <MainNavigation />
      <Container fluid="md">
        <Row>
          <Col>{props.children}</Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Layout
