import { Button, Card, Col, Row } from 'react-bootstrap'
import IndicatorList from '../components/Indicator/IndicatorList'

export default function Indicators() {
  return (
    <Row>
      <Col>
        <Card border="info" style={{ marginTop: '10px', padding: '10px' }}>
          <Row>
            <IndicatorList />
          </Row>
        </Card>
      </Col>
    </Row>
  )
}
