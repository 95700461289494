import { Card, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

export default function Dashboard() {
  const history = useHistory()

  return (
    <Row>
      <Col>
        <Card border="info" style={{ marginTop: '10px' }}>
          <Card.Body style={{ textAlign: 'center' }}>
            <h3>Coming Soon ... </h3>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
