import { Modal,Spinner } from 'react-bootstrap'

export default function LoadingPopup(props) {
  return (
    <Modal
      show={props.isLoading}
      backdrop="static"
      keyboard={false}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="warning" />
        <Spinner animation="grow" variant="info" />
        <Spinner animation="grow" variant="light" />
        <Spinner animation="grow" variant="dark" />
      </Modal.Body>
    </Modal>
  )
}
