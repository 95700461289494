import { Fragment, useEffect, useState, useContext } from 'react'
import { Row, Col, Spinner, Modal, Table, Button, Card } from 'react-bootstrap'
import AuthContext from '../../Store/AuthContext'
import configurationData from '../../configuration.json'
import { useHistory, useParams } from 'react-router-dom'
import { getData } from '../../utilities/dataProvider'

export default function ConfigDetails() {
  const authCTX = useContext(AuthContext)
  const history = useHistory()
  const [configDetails, setConfigDetails] = useState(null)
  const [tableHeaders, setTableHeaders] = useState([])
  const { configid } = useParams()
  const { env } = useParams()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const manageConfigDetails = async () => {
      let resp = await getConfigDetails()
      setTableHeaders(Object.keys(resp[0].configData[0]))
      setConfigDetails(resp[0])
      setIsLoading(false)
    }
    manageConfigDetails()
  }, [])

  const getConfigDetails = async () => {
    try {
      const GETCONFIGDETAILSSURL = `${configurationData.API_BASE_URL}/config/get/${configid}/${env}`
      let header = {
        token: authCTX.token,
        userId: authCTX.userId,
      }
      let resp = await getData(GETCONFIGDETAILSSURL, header)
      return resp.data
    } catch (error) {
      return []
    }
  }

  const handleBack = () => {
    history.push('/configs')
  }

  return (
    <Row>
      <Col>
        <Row>
          {isLoading && (
            <Modal
              show={isLoading}
              backdrop="static"
              keyboard={false}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body>
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="secondary" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
                <Spinner animation="grow" variant="light" />
                <Spinner animation="grow" variant="dark" />
              </Modal.Body>
            </Modal>
          )}
        </Row>
        {!isLoading && (
          <Row>
            <Col>
              {configDetails &&
                configDetails.configData &&
                configDetails.configData.length > 0 && (
                  <Fragment>
                    <Row>
                      <Col>
                        <Card
                          bg="success"
                          style={{ width: '16rem', marginTop: '10px' }}
                          className="mb-2"
                          text="white"
                        >
                          <Card.Header>
                            <b>Environment</b>
                          </Card.Header>
                          <Card.Body>
                            <Card.Title>{configDetails.environment}</Card.Title>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card
                          bg="success"
                          style={{ width: '16rem', marginTop: '10px' }}
                          className="mb-2"
                          text="white"
                        >
                          <Card.Header>
                            <b>Config Name</b>
                          </Card.Header>
                          <Card.Body>
                            <Card.Title>{configDetails.name}</Card.Title>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={6}></Col>
                    </Row>
                    <Row style={{ overflow: 'auto' }}>
                      <Table
                        striped
                        bordered
                        hover
                        variant="info"
                        size="sm"
                        style={{ marginTop: '2rem', width: '95%' }}
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            {tableHeaders.length > 0 &&
                              tableHeaders.map((head, i) => (
                                <th key={i}>{head}</th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {configDetails.configData.map((config, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {tableHeaders.length > 0 &&
                                tableHeaders.map((head, i) => (
                                  <td key={i}>{config[head]}</td>
                                ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Row>
                    <Row>
                      <Col>
                        <Button variant="secondary" onClick={handleBack}>
                          Back
                        </Button>{' '}
                      </Col>
                    </Row>
                  </Fragment>
                )}
              {configDetails === undefined ||
                (configDetails === null && <h4>No Record Exists</h4>)}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}
