import { useState, useContext, useEffect } from 'react'
import { Form, Button, Col, Row, Spinner, Modal, Card } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'
import { postDataWithHeader } from '../../utilities/dataProvider'
import AuthContext from '../../Store/AuthContext'
import configurationData from '../../configuration.json'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

export default function CreateSignal() {
  const SIGNALOPENAT_TEXT = 'Signal Creation DateTime'
  const authCTX = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const [selectedEnvName, setSelectedEnvName] = useState('Development')
  const [overrideOpenAt, setOverrideOpenAt] = useState('false')
  const [currentCloseAt, setcurrentCloseAt] = useState('false')

  const [signalData, setSignalData] = useState({
    clientid: '',
    signalid: uuidv4().split('-').join(''),
    direction: 'BUY',
    underlying_currency: '',
    open_price: '',
    close_price: '',
    take_profit: '',
    stop_loss: '',
    open_at: SIGNALOPENAT_TEXT,
    close_at: '',
    signal_strength: 'Normal',
    rationale: '',
    opt: 'True',
    status: 'OPEN',
    position_size: '1',
    pnl: '',
    closereason: '',
    signal_mode: 'N',
  })
  useEffect(() => {
    const RTL = getRandomRationaleValue()
    setSignalData((prevState) => ({
      ...prevState,
      rationale: RTL,
    }))
  }, [])

  const getRationaleSetValue = (SetData) => {
    let currentRandValue = Math.floor(Math.random() * SetData.length)
    return SetData[currentRandValue]
  }

  const getRandomRationaleValue = () => {
    let rationaleValue = ''
    const SetCount = 5
    for (let i = 0; i < SetCount; i++) {
      let valRn
      switch (i) {
        case 0:
          valRn = getRationaleSetValue(configurationData.Rationale.ASets)
          break
        case 1:
          valRn = getRationaleSetValue(configurationData.Rationale.BSets)
          break
        case 2:
          valRn = getRationaleSetValue(configurationData.Rationale.CSets)
          break
        case 3:
          valRn = getRationaleSetValue(configurationData.Rationale.DSets)
          break
        case 4:
          valRn = getRationaleSetValue(configurationData.Rationale.ESets)
          break
        default:
          break
      }
      if (i === 0) {
        rationaleValue += valRn
      } else {
        rationaleValue += ',' + valRn
      }
    }
    return rationaleValue
  }

  const handleNavigateSignal = () => {
    history.push('/signals')
  }

  const envOnChangeHandler = (event) => {
    setSelectedEnvName(event.target.value)
  }
  const overrideOpenAtHandler = (event) => {
    if (event.target.value === 'true') {
      setSignalData((prevState) => ({
        ...prevState,
        open_at: SIGNALOPENAT_TEXT,
      }))
      setOverrideOpenAt('false')
    } else {
      setSignalData((prevState) => ({
        ...prevState,
        open_at: moment().format('DD-MM-YYYY HH:mm:ss'),
      }))
      setOverrideOpenAt('true')
    }
  }
  const currentCloseAtHandler = (event) => {
    if (event.target.value === 'true') {
      setSignalData((prevState) => ({
        ...prevState,
        close_at: '',
      }))
      setcurrentCloseAt('false')
    } else {
      setSignalData((prevState) => ({
        ...prevState,
        close_at: moment().format('DD-MM-YYYY HH:mm:ss'),
      }))
      setcurrentCloseAt('true')
    }
  }
  const createSignal = async (result) => {
    try {
      setIsLoading(true)
      let dataToSubmit = { ...signalData }
      dataToSubmit = {
        ...dataToSubmit,
        underlying_currency: signalData.underlying_currency,
        clientid: parseInt(signalData.clientid),
        open_price: parseFloat(signalData.open_price),
        close_price:
          parseFloat(signalData.close_price) === 0 ||
          isNaN(parseFloat(signalData.close_price))
            ? null
            : parseFloat(signalData.close_price),
        stop_loss: parseFloat(signalData.stop_loss),
        opt: signalData.opt === 'True' ? true : false,
        open_at:
          overrideOpenAt === 'true'
            ? moment(signalData.open_at, 'DD-MM-YYYY HH:mm:ss')
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            : moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        close_at: signalData.close_at
          ? moment(signalData.close_at, 'DD-MM-YYYY HH:mm:ss')
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : null,
        position_size: parseInt(signalData.position_size),
        pnl:
          signalData.pnl && signalData.pnl.length > 0
            ? parseFloat(signalData.pnl)
            : null,
        closereason:
          signalData.closereason.length > 0 ? signalData.closereason : null,
        take_profit: parseFloat(signalData.take_profit),
      }
      let signalToBeSubmitted = {
        environment: selectedEnvName,
        signalData: dataToSubmit,
      }
      let header = {
        token: authCTX.token,
        userId: authCTX.userId,
      }
      console.log(dataToSubmit)
      const CREATESIGNAL = `${configurationData.API_BASE_URL}/signal/manage`

      let respD = await postDataWithHeader(
        CREATESIGNAL,
        signalToBeSubmitted,
        header,
      )

      setIsLoading(false)
      history.push('/signals')
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onFormValueChange = (event) => {
    switch (event.target.id) {
      case 'txt_Client_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          clientid: event.target.value,
        }))
        break
      }
      case 'txt_Signal_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          signalid: event.target.value,
        }))
        break
      }
      case 'rd_Direction_Buy_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          direction: event.target.value,
        }))
        break
      }
      case 'rd_Direction_Sell_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          direction: event.target.value,
        }))
        break
      }
      case 'txt_Currency': {
        setSignalData((prevState) => ({
          ...prevState,
          underlying_currency: event.target.value,
        }))
        break
      }
      case 'txt_Open_Price': {
        setSignalData((prevState) => ({
          ...prevState,
          open_price: event.target.value,
        }))
        break
      }
      case 'txt_Close_Price': {
        setSignalData((prevState) => ({
          ...prevState,
          close_price: event.target.value,
        }))
        break
      }
      case 'txt_Take_Profit': {
        setSignalData((prevState) => ({
          ...prevState,
          take_profit: event.target.value,
        }))
        break
      }
      case 'txt_Stop_Loss': {
        setSignalData((prevState) => ({
          ...prevState,
          stop_loss: event.target.value,
        }))
        break
      }
      case 'dt_Open_At': {
        if (
          true ||
          moment(event.target.value, 'DD-MM-YYYY HH:mm:ss', true).isValid()
        ) {
          setSignalData((prevState) => ({
            ...prevState,
            open_at: event.target.value,
          }))
        }
        break
      }
      case 'dt_Close_At': {
        if (
          true ||
          moment(event.target.value, 'DD-MM-YYYY HH:mm:ss', true).isValid()
        ) {
          setSignalData((prevState) => ({
            ...prevState,
            close_at: event.target.value,
          }))
        }
        break
      }
      case 'txt_Signal_Strength': {
        setSignalData((prevState) => ({
          ...prevState,
          signal_strength: event.target.value,
        }))
        break
      }
      case 'txt_Rationale': {
        setSignalData((prevState) => ({
          ...prevState,
          rationale: event.target.value,
        }))
        break
      }
      case 'rd_Opt_True_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          opt: event.target.value,
        }))
        break
      }
      case 'rd_Opt_False_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          opt: event.target.value,
        }))
        break
      }
      case 'rd_Status_Open_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          status: event.target.value,
          close_at: '',
        }))
        break
      }
      case 'rd_Status_Close_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          status: event.target.value,
          close_at: moment().format('DD-MM-YYYY HH:mm:ss'),
        }))
        break
      }
      case 'txt_Position_Size': {
        setSignalData((prevState) => ({
          ...prevState,
          position_size: event.target.value,
        }))
        break
      }
      case 'txt_pnl': {
        setSignalData((prevState) => ({
          ...prevState,
          pnl: event.target.value,
        }))
        break
      }
      case 'txt_Close_Reason': {
        setSignalData((prevState) => ({
          ...prevState,
          closereason: event.target.value,
        }))
        break
      }
      case 'rd_signalMode_N_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          signal_mode: event.target.value,
        }))
        break
      }
      case 'rd_signalMode_M_Id': {
        setSignalData((prevState) => ({
          ...prevState,
          signal_mode: event.target.value,
        }))
        break
      }
      default:
        break
    }
  }
  return (
    <Row>
      {isLoading && (
        <Modal
          show={isLoading}
          backdrop="static"
          keyboard={false}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="secondary" />
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="info" />
            <Spinner animation="grow" variant="light" />
            <Spinner animation="grow" variant="dark" />
          </Modal.Body>
        </Modal>
      )}

      <Card border="success" style={{ margin: '2rem 1rem 2rem 1rem' }} body>
        <Row>
          <h3>Create Signal Record</h3>
          <hr></hr>
        </Row>
        <Row>
          <Form.Group className="mb-3" controlId="configListEnv">
            <Form.Label>
              <b>Select Environment </b>
            </Form.Label>
            <select
              className="form-select"
              aria-label="Default select "
              value={selectedEnvName}
              onChange={envOnChangeHandler}
            >
              <option value="Development">Development</option>
              <option value="Production">Production</option>
            </select>
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Row>
              <Form.Group className="mb-3" controlId="txt_Client_Id">
                <Form.Label>
                  <b>Client ID * </b>
                </Form.Label>
                <Form.Control
                  id="txt_Client_Id"
                  type="text"
                  placeholder="Enter Client ID"
                  value={signalData.clientid}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formcurrencyid">
                <Form.Label>
                  <b>Currency * </b>
                </Form.Label>
                <Form.Control
                  id="txt_Currency"
                  type="text"
                  maxLength={16}
                  placeholder="Enter Currency Name"
                  value={signalData.underlying_currency}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <fieldset>
                <Form.Group className="mb-3" as={Row}>
                  <Form.Label as="legend" column sm={2}>
                    <b>Status</b>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Check
                      type="radio"
                      label="Open"
                      value="OPEN"
                      defaultChecked={'OPEN' === signalData.status}
                      name="rd_Status"
                      id="rd_Status_Open_Id"
                      onChange={onFormValueChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Close"
                      defaultChecked={'CLOSE' === signalData.status}
                      value="CLOSE"
                      name="rd_Status"
                      id="rd_Status_Close_Id"
                      onChange={onFormValueChange}
                    />
                  </Col>
                  <Form.Label as="legend" column sm={2}>
                    <b>Direction</b>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Check
                      type="radio"
                      label="Buy"
                      value="BUY"
                      defaultChecked={'BUY' === signalData.direction}
                      name="rd_Direction"
                      id="rd_Direction_Buy_Id"
                      onChange={onFormValueChange}
                    />
                    <Form.Check
                      type="radio"
                      label="Sell"
                      defaultChecked={'SELL' === signalData.direction}
                      value="SELL"
                      name="rd_Direction"
                      id="rd_Direction_Sell_Id"
                      onChange={onFormValueChange}
                    />
                  </Col>
                </Form.Group>
              </fieldset>
              <br></br>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Open At * </b>
                </Form.Label>
                <input
                  type="checkbox"
                  style={{ marginLeft: '15px' }}
                  id="override_open_at"
                  value={overrideOpenAt}
                  onChange={overrideOpenAtHandler}
                />
                <label style={{ paddingLeft: '5px' }} for="override_open_at">
                  Override
                </label>
                <Form.Control
                  type="text"
                  id="dt_Open_At"
                  placeholder="DD-MM-YYYY HH:MM:SS"
                  disabled={overrideOpenAt === 'false'}
                  value={signalData.open_at}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formName01">
                <Form.Label>
                  <b>Rationale * </b>
                </Form.Label>
                <Form.Control
                  id="txt_Rationale"
                  type="text"
                  placeholder="Enter Rationale"
                  value={signalData.rationale}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formopenprice">
                <Form.Label>
                  <b>Open Price * </b>
                </Form.Label>
                <Form.Control
                  id="txt_Open_Price"
                  type="text"
                  placeholder="Enter Open Price"
                  value={signalData.open_price}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Take Profit * </b>
                </Form.Label>
                <Form.Control
                  id="txt_Take_Profit"
                  type="text"
                  placeholder="Enter Take Profit"
                  value={signalData.take_profit}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Position Size * </b>
                </Form.Label>
                <Form.Control
                  id="txt_Position_Size"
                  type="text"
                  placeholder="Enter Position Size"
                  value={signalData.position_size}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
          </Col>
          <Col>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Signal ID * </b>
                </Form.Label>
                <Form.Control
                  id="txt_Signal_Id"
                  type="text"
                  value={signalData.signalid}
                  maxLength={32}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formName01">
                <Form.Label>
                  <b>Signal Strength * </b>
                </Form.Label>
                <Form.Control
                  id="txt_Signal_Strength"
                  type="text"
                  placeholder="Enter Signal Strength"
                  value={signalData.signal_strength}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <fieldset>
                <Form.Group className="mb-3" as={Row}>
                  <Form.Label as="legend" column sm={2}>
                    <b>Opt</b>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Check
                      type="radio"
                      label="True"
                      value="True"
                      defaultChecked={'True' === signalData.opt}
                      name="rd_Opt"
                      id="rd_Opt_True_Id"
                      onChange={onFormValueChange}
                    />
                    <Form.Check
                      type="radio"
                      label="False"
                      defaultChecked={'False' === signalData.opt}
                      value="False"
                      name="rd_Opt"
                      id="rd_Opt_False_Id"
                      onChange={onFormValueChange}
                    />
                  </Col>
                  <Form.Label as="legend" column sm={3}>
                    <b>Signal Mode </b>
                  </Form.Label>
                  <Col sm={3}>
                    <Form.Check
                      type="radio"
                      label="N"
                      value="N"
                      defaultChecked={'N' === signalData.signal_mode}
                      name="rd_signalMode"
                      id="rd_signalMode_N_Id"
                      onChange={onFormValueChange}
                    />
                    <Form.Check
                      type="radio"
                      label="M"
                      defaultChecked={'M' === signalData.signal_mode}
                      value="M"
                      name="rd_signalMode"
                      id="rd_signalMode_M_Id"
                      onChange={onFormValueChange}
                    />
                  </Col>
                </Form.Group>
              </fieldset>
              <br></br>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formName01">
                <Form.Label>
                  <b>Close At </b>
                </Form.Label>
                <input
                  type="checkbox"
                  style={{ marginLeft: '15px' }}
                  id="override_close_at"
                  value={currentCloseAt}
                  onChange={currentCloseAtHandler}
                />
                <label style={{ paddingLeft: '5px' }} for="override_close_at">
                  Set Current DateTime
                </label>
                <Form.Control
                  type="text"
                  id="dt_Close_At"
                  placeholder="DD-MM-YYYY HH:MM:SS"
                  value={signalData.close_at}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formName01">
                <Form.Label>
                  <b>Close Reason </b>
                </Form.Label>
                <Form.Control
                  id="txt_Close_Reason"
                  type="text"
                  placeholder="Enter Close Reason"
                  value={signalData.closereason}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Close Price </b>
                </Form.Label>
                <Form.Control
                  id="txt_Close_Price"
                  type="text"
                  placeholder="Enter Close Price"
                  value={signalData.close_price}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formStoploss">
                <Form.Label>
                  <b>Stop Loss * </b>
                </Form.Label>
                <Form.Control
                  id="txt_Stop_Loss"
                  type="text"
                  placeholder="Enter Stop Loss "
                  value={signalData.stop_loss}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="formName01">
                <Form.Label>
                  <b>PNL </b>
                </Form.Label>
                <Form.Control
                  id="txt_pnl"
                  type="text"
                  placeholder="Enter PNL"
                  value={signalData.pnl}
                  onChange={onFormValueChange}
                ></Form.Control>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '3rem' }}>
          <Col sm={1}>
            <Button variant="secondary" onClick={handleNavigateSignal}>
              Cancel
            </Button>
          </Col>
          <Col sm={1}>
            <Button
              variant="primary"
              disabled={
                signalData.clientid.length === 0 ||
                signalData.signalid.length === 0 ||
                signalData.underlying_currency.length === 0 ||
                signalData.signal_strength.length === 0 ||
                signalData.open_at.length === 0 ||
                signalData.rationale.length === 0 ||
                signalData.open_price.length === 0 ||
                signalData.take_profit.length === 0 ||
                signalData.stop_loss.length === 0 ||
                signalData.position_size.length === 0
              }
              onClick={createSignal}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  )
}
