import { useEffect, useState, useContext } from 'react'
import {
  Form,
  Row,
  Col,
  Modal,
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
  Card,
} from 'react-bootstrap'
import { getData } from '../../utilities/dataProvider'
import AuthContext from '../../Store/AuthContext'
import configurationData from '../../configuration.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import classes from './IndicatorList.module.css'
import { BsArrowClockwise } from 'react-icons/bs'
import LoadingPopup from '../../utilities/loadingPopup'

export default function IndicatorList() {
  const authCTX = useContext(AuthContext)
  const [indicatorData, setindicatorData] = useState({})
  const [currencyList, setCurrencyList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [
    currentSelectedIndicatorEnv,
    setCurrentSelectedIndicatorEnv,
  ] = useState('Development')
  const [
    showModalForViewIndicatorDetails,
    setShowModalForViewIndicatorDetails,
  ] = useState(false)

  const [selectedIndicatorToView, setSelectedIndicatorToView] = useState(null)

  useEffect(() => {
    getAllIndicatorValues()
  }, [currentSelectedIndicatorEnv])

  const getAllIndicatorValues = async () => {
    try {
      setIsLoading(true)
      if (authCTX.userId === null) return null
      const GETINDICATORSURL = `${configurationData.API_BASE_URL}/indicator/getall/${currentSelectedIndicatorEnv}`
      let header = {
        token: authCTX.token,
        userId: authCTX.userId,
      }
      let resp = await getData(GETINDICATORSURL, header)
      if (resp && resp.data) setindicatorData(resp.data)
      let keys = Object.keys(resp.data)
      let filteredAry = keys.filter((e) => e.toLowerCase() !== 'timestamp')
      setCurrencyList(filteredAry)

      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const envOnChangeHandler = (event) => {
    setCurrentSelectedIndicatorEnv(event.target.value)
  }

  const openIndicatorDetails = (dataInd) => {
    setSelectedIndicatorToView(dataInd)
    setShowModalForViewIndicatorDetails(true)
  }

  const handleCloseModal = () => {
    setShowModalForViewIndicatorDetails(false)
    setSelectedIndicatorToView(null)
  }

  const refreshIndicatorList = () => {
    getAllIndicatorValues()
  }

  return (
    <Row>
      <Col>
        <LoadingPopup isLoading={isLoading} />
        <Modal
          show={showModalForViewIndicatorDetails}
          onHide={handleCloseModal}
          backdrop="static"
          keyboard={false}
          centered
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>
              <h4>Indicator Details - {selectedIndicatorToView}</h4>
            </Modal.Title>
          </Modal.Header>
          {selectedIndicatorToView && (
            <Modal.Body>
              <Row>
                <Col>
                  <Card
                    border="primary"
                    className={[classes.cardSizeAdjust, 'text-center']}
                  >
                    <Card.Header className={classes.cardHearerOrange}>
                      SMA
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <label>
                          High :{' '}
                          {indicatorData[selectedIndicatorToView].SMA.high}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Low : {indicatorData[selectedIndicatorToView].SMA.low}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Open :{' '}
                          {indicatorData[selectedIndicatorToView].SMA.open}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Close :{' '}
                          {indicatorData[selectedIndicatorToView].SMA.close}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Period :{' '}
                          {indicatorData[selectedIndicatorToView].SMA.period}
                        </label>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    border="primary"
                    className={[classes.cardSizeAdjust, 'text-center']}
                  >
                    <Card.Header className={classes.cardHearerOrange}>
                      EMA
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <label>
                          High :{' '}
                          {indicatorData[selectedIndicatorToView].EMA.high}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Low : {indicatorData[selectedIndicatorToView].EMA.low}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Open :{' '}
                          {indicatorData[selectedIndicatorToView].EMA.open}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Close :{' '}
                          {indicatorData[selectedIndicatorToView].EMA.close}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Period :{' '}
                          {indicatorData[selectedIndicatorToView].EMA.period}
                        </label>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    border="primary"
                    className={[classes.cardSizeAdjust, 'text-center']}
                  >
                    <Card.Header className={classes.cardHearerOrange}>
                      Bollinger Band
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <label>
                          Upper :{' '}
                          {indicatorData[selectedIndicatorToView].BB.upper}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Lower :{' '}
                          {indicatorData[selectedIndicatorToView].BB.lower}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Min-Upper :{' '}
                          {indicatorData[selectedIndicatorToView].BB.minUpper}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Min-Lower :{' '}
                          {indicatorData[selectedIndicatorToView].BB.minLower}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Period :{' '}
                          {indicatorData[selectedIndicatorToView].BB.period}
                        </label>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    border="primary"
                    className={[classes.cardSizeAdjust, 'text-center']}
                  >
                    <Card.Header className={classes.cardHearerOrange}>
                      ATR
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <label>
                          Value :{' '}
                          {indicatorData[selectedIndicatorToView].ATR.atr}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Period :{' '}
                          {indicatorData[selectedIndicatorToView].ATR.period}
                        </label>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    border="primary"
                    className={[classes.cardSizeAdjust, 'text-center']}
                  >
                    <Card.Header className={classes.cardHearerOrange}>
                      ADX
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <label>
                          Value :{' '}
                          {indicatorData[selectedIndicatorToView].ADX.adx}
                        </label>
                      </Row>
                      <Row>
                        <label>
                          Period :{' '}
                          {indicatorData[selectedIndicatorToView].ADX.period}
                        </label>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card
                    border="primary"
                    className="text-center"
                    style={{ marginTop: '15px' }}
                  >
                    <Card.Header className={classes.cardHearerOrange}>
                      EMA Bar Trends
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        {indicatorData[selectedIndicatorToView].EMABarTrends &&
                          indicatorData[selectedIndicatorToView].EMABarTrends
                            .length > 0 &&
                          indicatorData[
                            selectedIndicatorToView
                          ].EMABarTrends.map((item, index) => (
                            <Col key={index}>
                              <Card border="info" className="text-center">
                                <Card.Header
                                  className={classes.cardHearerGreenYellow}
                                >
                                  {item.period}
                                </Card.Header>
                                <Card.Body>
                                  {item.barCountsTrend &&
                                    item.barCountsTrend.length > 0 &&
                                    item.barCountsTrend.map((bar, indx) => (
                                      <Row key={indx}>
                                        <label>
                                          {bar.count} : {bar.ema}
                                        </label>
                                      </Row>
                                    ))}
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col xs={3}>
            <Form.Group className="mb-3" controlId="indicatorListEnv">
              <Form.Label>
                <b>Select Environment *</b>
              </Form.Label>
              <select
                className="form-select"
                aria-label="Indicator Env select "
                value={currentSelectedIndicatorEnv}
                onChange={envOnChangeHandler}
              >
                <option value="Development">Development</option>
                <option value="Production">Production</option>
              </select>
            </Form.Group>
          </Col>
          <Col xs={{ span: 1, offset: 8 }} style={{ paddingTop: '0.5rem' }}>
            <OverlayTrigger
              overlay={<Tooltip id="refresh-tooltip-button">Refresh</Tooltip>}
            >
              <BsArrowClockwise
                className={classes.refreshIcon}
                onClick={refreshIndicatorList}
              />
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          {currencyList.length > 0 && (
            <Table
              striped
              bordered
              hover
              size="sm"
              className={classes.tableIndicator}
            >
              <thead>
                <tr style={{ backgroundColor: 'beige', fontSize: '1.2rem' }}>
                  <th>Currency</th>
                  <th>Indicator Date</th>
                  <th>High</th>
                  <th>Low</th>
                  <th>Open</th>
                  <th>Close</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currencyList.map((item, index) => (
                  <tr key={item}>
                    <td>{item}</td>
                    <td>{indicatorData.TimeStamp}</td>
                    <td>{indicatorData[item].CurrentData.high}</td>
                    <td>{indicatorData[item].CurrentData.low}</td>
                    <td>{indicatorData[item].CurrentData.open}</td>
                    <td>{indicatorData[item].CurrentData.close}</td>
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        size="2x"
                        className={classes.eyeIcon}
                        onClick={() => openIndicatorDetails(item)}
                      ></FontAwesomeIcon>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Row>
      </Col>
    </Row>
  )
}
