import { Button, Card, Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ConfigList from '../components/Config/ConfigList'

export default function Configs() {
  const history = useHistory()
  const handleNavigateCreate = () => {
    history.push('/configs/create')
  }
  return (
    <Row>
      <Col>
        <Card border="info" style={{ marginTop: '10px' }}>
          <Card.Body>
            <Row>
              <ConfigList></ConfigList>
            </Row>
            <Row>
              <Col>
                <br />
                <Button variant="primary" onClick={handleNavigateCreate}>
                  Create Config
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
