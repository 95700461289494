import React, { useState } from 'react'

const AuthContext = React.createContext({
  token: '',
  userId: '',
  isLoggedIn: false,
  login: (token, userId) => {},
  logout: () => {},
})

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem('authToken')
  const initialUserId = localStorage.getItem('userId')
  const [token, setToken] = useState(initialToken)
  const [userId, setUserId] = useState(initialUserId)
  const userLoggedIn = !!token && !!userId

  const loginHandlerContext = (token, userId) => {
    localStorage.setItem('authToken', token)
    localStorage.setItem('userId', userId)
    setToken(token)
    setUserId(userId)
  }
  const logoutHandlerContext = () => {
    localStorage.removeItem('authToken')
    localStorage.removeItem('userId')
    setToken(null)
    setUserId(null)
  }
  const contextValue = {
    userId: userId,
    token: token,
    isLoggedIn: userLoggedIn,
    login: loginHandlerContext,
    logout: logoutHandlerContext,
  }
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext
