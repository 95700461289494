import { NavLink } from 'react-router-dom'
import { useContext } from 'react'
import classes from './MainNavigation.module.css'
import AuthContext from '../../Store/AuthContext'

const MainNavigation = () => {
  const authCTX = useContext(AuthContext)
  const isLoggedIn = authCTX.isLoggedIn

  const logoutHandler = () => {
    authCTX.logout()
  }
  return (
    <header className={classes.header}>
      <NavLink to="/">
        <div className={classes.logo}>Config Web App</div>
      </NavLink>
      <nav>
        <ul>
          {isLoggedIn && (
            <li>
              <NavLink activeClassName={classes.active} to="/Dashboard">
                Dashboard
              </NavLink>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <NavLink activeClassName={classes.active} to="/Configs">
                Configs
              </NavLink>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <NavLink activeClassName={classes.active} to="/Signals">
                Signals
              </NavLink>
            </li>
          )}
                    {isLoggedIn && (
            <li>
              <NavLink activeClassName={classes.active} to="/Indicator">
                Indicator
              </NavLink>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button onClick={logoutHandler}>Logout</button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  )
}

export default MainNavigation
