import { Switch, Route, Redirect } from 'react-router-dom'
import { useContext } from 'react'

import AuthContext from './Store/AuthContext'
import Layout from './components/Layout/Layout'
import AuthPage from './pages/AuthPage'
import Dashboard from './pages/Dashboard'
import UploadConfigFile from './components/Config/UploadConfigFile'
import ConfigDetails from './components/Config/ConfigDetails'
import Configs from './pages/Configs'
import Signals from './pages/Signals'
import CreateSignal from './components/Signal/CreateSignal'
import Indicators from './pages/Indicators'

function App() {
  const authCTX = useContext(AuthContext)
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          {!authCTX.isLoggedIn && <Redirect to="/auth" />}
          {authCTX.isLoggedIn && <Redirect to="/dashboard" />}
        </Route>
        {!authCTX.isLoggedIn && (
          <Route path="/auth">
            <AuthPage />
          </Route>
        )}
        {authCTX.isLoggedIn && (
          <Route path="/dashboard">
            <Dashboard />
          </Route>
        )}

        {authCTX.isLoggedIn && (
          <Route path="/signals/add">
            <CreateSignal />
          </Route>
        )}
        {authCTX.isLoggedIn && (
          <Route path="/signals">
            <Signals />
          </Route>
        )}
        {authCTX.isLoggedIn && (
          <Route path="/indicator">
            <Indicators />
          </Route>
        )}
        {authCTX.isLoggedIn && (
          <Route path="/configs/create">
            <UploadConfigFile />
          </Route>
        )}
        {authCTX.isLoggedIn && (
          <Route path="/configs">
            <Configs />
          </Route>
        )}
        {authCTX.isLoggedIn && (
          <Route path="/manage/:configid/:env">
            <ConfigDetails />
          </Route>
        )}
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Layout>
  )
}

export default App
