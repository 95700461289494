import { useRef, useState, useContext } from 'react'
import { Form, Button, Col, Row, Spinner, Modal, Card } from 'react-bootstrap'
import Papa from 'papaparse'
import { useHistory } from 'react-router-dom'
import { postDataWithHeader } from '../../utilities/dataProvider'
import AuthContext from '../../Store/AuthContext'
import configurationData from '../../configuration.json'

export default function UploadConfigFile() {
  const authCTX = useContext(AuthContext)
  const configName = useRef()
  const history = useHistory()
  const [selectedFile, setSelectedFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedEnvName, setSelectedEnvName] = useState('Production')

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0])
  }
  const handleFileUpload = () => {
    Papa.parse(selectedFile, {
      complete: sendData,
      header: true,
    })
  }
  const envOnChangeHandler = (event) => {
    setSelectedEnvName(event.target.value)
  }
  const sendData = async (result) => {
    try {
      setIsLoading(true)
      result.data.pop()
      let configToBeSubmitted = {
        name: configName.current.value,
        environment: selectedEnvName,
        configData: result.data,
      }
      let header = {
        token: authCTX.token,
        userId: authCTX.userId,
      }
      const CREATECONFIG = `${configurationData.API_BASE_URL}/config/manage`
      await postDataWithHeader(CREATECONFIG, configToBeSubmitted, header)
      history.push('/configs')
    } catch (error) {
      setIsLoading(false)
    }
  }
  const handleCancel = () => {
    history.push('/configs')
  }
  return (
    <Row>
      <Col>
        <Row>
          {isLoading && (
            <Modal
              show={isLoading}
              backdrop="static"
              keyboard={false}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body>
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="secondary" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="info" />
                <Spinner animation="grow" variant="light" />
                <Spinner animation="grow" variant="dark" />
              </Modal.Body>
            </Modal>
          )}
        </Row>
        <Row>
          <Col>
            <Card border="success" style={{ margin: '2rem' }} body>
              <Form.Group className="mb-3" controlId="configListEnv">
                <Form.Label>
                  <b>Select Environment *</b>
                </Form.Label>
                <select
                  className="form-select"
                  aria-label="Default select "
                  defaultValue={selectedEnvName}
                  onChange={envOnChangeHandler}
                >
                  <option value="Development">Development</option>
                  <option value="Production">Production</option>
                </select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formName01">
                <Form.Label>
                  <b>Config Name * </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Config Name"
                  ref={configName}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <b>Upload Symbol File</b>
                </Form.Label>
                <Form.File
                  id="symbolCSVFileUploader"
                  accept=".csv"
                  onChange={handleFileSelect}
                  label=""
                />
                <Form.Text className="text-muted">
                  Note: Only CSV file format is supported for upload
                </Form.Text>
                <br />
                <hr />
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>{' '}
                <Button
                  variant="primary"
                  onClick={handleFileUpload}
                  disabled={selectedFile === null}
                >
                  Submit
                </Button>
              </Form.Group>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
