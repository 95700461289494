import { useEffect, useState, useContext, Fragment } from 'react'
import {
  Form,
  Row,
  Col,
  Spinner,
  Modal,
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { getData, postDataWithHeader } from '../../utilities/dataProvider'
import AuthContext from '../../Store/AuthContext'
import configurationData from '../../configuration.json'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import classes from './SignalList.module.css'
import { BsArrowClockwise } from 'react-icons/bs'
import moment from 'moment'

export default function SignalList() {
  const authCTX = useContext(AuthContext)
  const [signalList, setSignalList] = useState([])
  const [signalCurrencyList, setSignalCurrencyList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const MaxRecordCount = 250
  const [currentSelectedSignalEnv, setCurrentSelectedSignalEnv] = useState(
    'Development',
  )
  const [
    showModalForViewSignalDetails,
    setShowModalForViewSignalDetails,
  ] = useState(false)
  const [showModalForCloseSignal, setShowModalForCloseSignalSignal] = useState(
    false,
  )
  const [selectedSignalToView, setSelectedSignalToView] = useState(null)
  const [selectedSignalTClose, setSelectedSignalToClose] = useState(null)
  const [
    currentFilterForSignalsCurrency,
    setCurrentFilterForSignalsCurrency,
  ] = useState('All')
  const [
    currentFilterForSignalsMode,
    setCurrentFilterForSignalsMode,
  ] = useState('All')
  const [
    currentFilterForSignalsStatus,
    setCurrentFilterForSignalsStatus,
  ] = useState('All')
  const [
    currentFilterForSignalsCount,
    setCurrentFilterForSignalsCount,
  ] = useState('10')
  const [signalListDataRaw, setSignalListDataRaw] = useState([])
  const [signalDataToClose, setSignalDataToClose] = useState({
    close_price: '',
    close_at: moment().format('DD-MM-YYYY HH:mm:ss'),
    status: 'CLOSE',
    pnl: '',
    closereason: '',
  })
  useEffect(() => {
    getSignalList()
  }, [currentSelectedSignalEnv])

  const getSignalList = async () => {
    try {
      setIsLoading(true)
      if (authCTX.userId === null) return []
      const GETSIGNALSURL = `${configurationData.API_BASE_URL}/signal/getall/${currentSelectedSignalEnv}/${MaxRecordCount}`
      let header = {
        token: authCTX.token,
        userId: authCTX.userId,
      }
      let resp = await getData(GETSIGNALSURL, header)
      let signalListDataRaw = resp.data ?? []
      let signalListDataInitialFiltered =
        signalListDataRaw.length > 0
          ? signalListDataRaw.slice(0, parseInt(currentFilterForSignalsCount))
          : signalListDataRaw
      setSignalListDataRaw(signalListDataRaw)
      setSignalList(signalListDataInitialFiltered)
      setSignalCurrencyList([
        ...new Set(
          signalListDataRaw.map((item) => {
            return item.underlying_currency
          }),
        ),
      ])
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const envOnChangeHandler = (event) => {
    setCurrentSelectedSignalEnv(event.target.value)
  }

  const onSignalFilterChange = (event) => {
    switch (event.target.id) {
      case 'signalFilterCurrency': {
        setCurrentFilterForSignalsCurrency(event.target.value)
        doCurrencyFilter(event.target.value)
        break
      }
      case 'signalFilterMode': {
        setCurrentFilterForSignalsMode(event.target.value)
        doModeFilter(event.target.value)
        break
      }
      case 'signalFilterRecordCount': {
        setCurrentFilterForSignalsCount(event.target.value)
        doRecordCountFilter(event.target.value)
        break
      }
      case 'signalFilterStatus': {
        setCurrentFilterForSignalsStatus(event.target.value)
        doStatusFilter(event.target.value)
        break
      }
      default:
        break
    }
  }

  const doStatusFilter = (valueToFilter) => {
    if (
      currentFilterForSignalsMode === 'All' &&
      parseInt(currentFilterForSignalsCount) === MaxRecordCount &&
      currentFilterForSignalsCurrency === 'All' &&
      valueToFilter === 'All'
    ) {
      setSignalList(signalListDataRaw)
    } else {
      let filterData =
        valueToFilter === 'All'
          ? signalListDataRaw
          : signalListDataRaw.filter((item) => {
              return item.status === valueToFilter
            })
      filterData = filterData.length > 0 ? doCurrencyFilterFor(filterData) : []
      filterData = filterData.length > 0 ? doModeFilterFor(filterData) : []
      filterData =
        filterData.length > 0 ? doRecordCountFilterFor(filterData) : []

      setSignalList(filterData)
    }
  }

  const doCurrencyFilter = (valueToFilter) => {
    if (
      currentFilterForSignalsMode === 'All' &&
      parseInt(currentFilterForSignalsCount) === MaxRecordCount &&
      valueToFilter === 'All'
    ) {
      setSignalList(signalListDataRaw)
    } else {
      let filterData =
        valueToFilter === 'All'
          ? signalListDataRaw
          : signalListDataRaw.filter((item) => {
              return item.underlying_currency === valueToFilter
            })
      filterData = filterData.length > 0 ? doModeFilterFor(filterData) : []
      filterData = filterData.length > 0 ? doStatusFilterFor(filterData) : []

      filterData =
        filterData.length > 0 ? doRecordCountFilterFor(filterData) : []

      setSignalList(filterData)
    }
  }

  const doModeFilterFor = (filteredData) => {
    if (currentFilterForSignalsMode !== 'All') {
      let modeFilteredData = filteredData.filter((item) => {
        return item.signal_mode === currentFilterForSignalsMode
      })
      return modeFilteredData
    }
    return filteredData
  }

  const doStatusFilterFor = (filteredData) => {
    if (currentFilterForSignalsStatus !== 'All') {
      let statusFilteredData = filteredData.filter((item) => {
        return item.status === currentFilterForSignalsStatus
      })
      return statusFilteredData
    }
    return filteredData
  }

  const onCloseFormValueChange = (event) => {
    switch (event.target.id) {
      case 'txt_Close_Price': {
        setSignalDataToClose((prevState) => ({
          ...prevState,
          close_price: event.target.value,
        }))
        break
      }

      case 'dt_Close_At': {
        if (
          true ||
          moment(event.target.value, 'DD-MM-YYYY HH:mm:ss', true).isValid()
        ) {
          setSignalDataToClose((prevState) => ({
            ...prevState,
            close_at: event.target.value,
          }))
        }
        break
      }
      case 'rd_CloseReason_SL_Id':
      case 'rd_CloseReason_TP_Id':
      case 'rd_CloseReason_buy_cond_Id':
      case 'rd_CloseReason_sell_cond_Id':
      case 'rd_CloseReason_ema_no_more_trending_Id':
      case 'rd_CloseReason_max_minutes_in_trade_Id': {
        setSignalDataToClose((prevState) => ({
          ...prevState,
          closereason: event.target.value,
        }))
        break
      }

      case 'txt_pnl': {
        setSignalDataToClose((prevState) => ({
          ...prevState,
          pnl: event.target.value,
        }))
        break
      }

      default:
        break
    }
  }

  /*
  const doDurationFilterFor = (filteredData) => {
    if (currentFilterForSignalsDuration !== 'All') {
      let filterDateUTC
      switch (currentFilterForSignalsDuration) {
        case 'M30': {
          filterDateUTC = moment()
            .utc()
            .subtract(30, 'm')
            .format('YYYY-MM-DDTHH:mm:ss:SSS[Z]')
          break
        }
        case 'H01': {
          filterDateUTC = moment()
            .utc()
            .subtract(1, 'h')
            .format('YYYY-MM-DDTHH:mm:ss:SSS[Z]')
          break
        }
        case 'H03': {
          filterDateUTC = moment()
            .utc()
            .subtract(3, 'h')
            .format('YYYY-MM-DDTHH:mm:ss:SSS[Z]')
          break
        }
        case 'H06': {
          filterDateUTC = moment()
            .utc()
            .subtract(6, 'h')
            .format('YYYY-MM-DDTHH:mm:ss:SSS[Z]')
          break
        }
        case 'H12': {
          filterDateUTC = moment()
            .utc()
            .subtract(12, 'h')
            .format('YYYY-MM-DDTHH:mm:ss:SSS[Z]')
          break
        }
        case 'H24': {
          filterDateUTC = moment()
            .utc()
            .subtract(24, 'h')
            .format('YYYY-MM-DDTHH:mm:ss:SSS[Z]')
          break
        }
        default:
          break
      }
      let durationFilteredData = filteredData.filter((item) => {
        return moment(filterDateUTC).isAfter(item.datetime_of_creation)
      })
      return durationFilteredData
    }
    return filteredData
  }
  */

  const doCurrencyFilterFor = (filteredData) => {
    if (currentFilterForSignalsCurrency !== 'All') {
      let currencyFilteredData = filteredData.filter((item) => {
        return item.underlying_currency === currentFilterForSignalsCurrency
      })
      return currencyFilteredData
    }
    return filteredData
  }

  const doRecordCountFilter = (valueToFilter) => {
    let filterData = signalListDataRaw.slice(0, parseInt(valueToFilter))
    filterData = filterData.length > 0 ? doCurrencyFilterFor(filterData) : []
    filterData = filterData.length > 0 ? doModeFilterFor(filterData) : []
    filterData = filterData.length > 0 ? doStatusFilterFor(filterData) : []
    setSignalList(filterData)
  }

  const doRecordCountFilterFor = (filteredData) => {
    let filterData = filteredData.slice(
      0,
      parseInt(currentFilterForSignalsCount),
    )
    return filterData
  }

  const doModeFilter = (valueToFilter) => {
    if (
      currentFilterForSignalsCurrency === 'All' &&
      parseInt(currentFilterForSignalsCount) === MaxRecordCount &&
      valueToFilter === 'All'
    ) {
      setSignalList(signalListDataRaw)
    } else {
      let filterData =
        valueToFilter === 'All'
          ? signalListDataRaw
          : signalListDataRaw.filter((item) => {
              return item.signal_mode === valueToFilter
            })
      filterData = filterData.length > 0 ? doCurrencyFilterFor(filterData) : []
      filterData = filterData.length > 0 ? doStatusFilterFor(filterData) : []
      filterData =
        filterData.length > 0 ? doRecordCountFilterFor(filterData) : []
      setSignalList(filterData)
    }
  }

  /*
  const doDurationFilter = (valueToFilter) => {
    if (
      currentFilterForSignalsCurrency === 'All' &&
      currentFilterForSignalsMode === 'All' &&
      valueToFilter === 'All'
    ) {
      setSignalList(signalListDataRaw)
    } else {
      let filterDateUTC
      switch (valueToFilter) {
        case 'M30': {
          filterDateUTC = moment().subtract(30, 'm').utc()
          break
        }
        case 'H01': {
          filterDateUTC = moment()
            .utc()
            .subtract(1, 'h')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }
        case 'H03': {
          filterDateUTC = moment()
            .utc()
            .subtract(3, 'h')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }
        case 'H06': {
          filterDateUTC = moment()
            .utc()
            .subtract(6, 'h')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }
        case 'H12': {
          filterDateUTC = moment()
            .utc()
            .subtract(12, 'h')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }
        case 'H24': {
          filterDateUTC = moment()
            .utc()
            .subtract(24, 'h')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          break
        }
        default:
          break
      }
      let filterData =
        valueToFilter === 'All'
          ? signalListDataRaw
          : signalListDataRaw.filter((item) => {
              return moment(filterDateUTC).isBefore(item.datetime_of_creation)
            })
      filterData = filterData.length > 0 ? doCurrencyFilterFor(filterData) : []
      filterData = filterData.length > 0 ? doModeFilterFor(filterData) : []
      setSignalList(filterData)
    }
  }
  */

  const refreshSignalList = () => {
    setCurrentFilterForSignalsCurrency('All')
    setCurrentFilterForSignalsMode('All')
    setCurrentFilterForSignalsCount('10')
    setCurrentFilterForSignalsStatus('All')
    getSignalList()
  }
  const openSingalDetails = (signalData) => {
    setSelectedSignalToView(signalData)
    setShowModalForViewSignalDetails(true)
  }

  const openSingalFormToClose = (signalData) => {
    setSelectedSignalToClose(signalData)
    setShowModalForCloseSignalSignal(true)
  }

  const closeSignal = async (id) => {
    try {
      setIsLoading(true)
      let dataToSubmit = { ...signalDataToClose }
      dataToSubmit = {
        ...dataToSubmit,
        close_price: parseFloat(signalDataToClose.close_price),
        close_at: signalDataToClose.close_at
          ? moment(signalDataToClose.close_at, 'DD-MM-YYYY HH:mm:ss')
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : null,
        pnl: parseFloat(signalDataToClose.pnl),
        id: id,
      }
      let signalToBeSubmitted = {
        environment: currentSelectedSignalEnv,
        signalData: dataToSubmit,
      }
      let header = {
        token: authCTX.token,
        userId: authCTX.userId,
      }
      console.log(dataToSubmit)
      const CLOSESIGNAL = `${configurationData.API_BASE_URL}/signal/closesignal`

      let respD = await postDataWithHeader(
        CLOSESIGNAL,
        signalToBeSubmitted,
        header,
      )
      handleCloseModalForCloseForm()
      setIsLoading(false)
      //history.push('/signals')
      window.location.reload()
    } catch (error) {
      setIsLoading(false)
    }
  }
  const handleCloseModal = () => {
    setShowModalForViewSignalDetails(false)
    setSelectedSignalToView(null)
  }

  const handleCloseModalForCloseForm = () => {
    setSelectedSignalToClose(null)
    setShowModalForCloseSignalSignal(false)
  }

  return (
    <Row>
      <Col>
        <Row>
          <Modal
            show={isLoading}
            backdrop="static"
            keyboard={false}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <Spinner animation="grow" variant="primary" />
              <Spinner animation="grow" variant="secondary" />
              <Spinner animation="grow" variant="success" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="warning" />
              <Spinner animation="grow" variant="info" />
              <Spinner animation="grow" variant="light" />
              <Spinner animation="grow" variant="dark" />
            </Modal.Body>
          </Modal>
        </Row>
        {!isLoading && (
          <Row>
            <Col>
              <Row>
                <Col xs={3}>
                  <Form.Group className="mb-3" controlId="signalListEnv">
                    <Form.Label>
                      <b>Select Environment *</b>
                    </Form.Label>
                    <select
                      className="form-select"
                      aria-label="Signal Env select "
                      value={currentSelectedSignalEnv}
                      onChange={envOnChangeHandler}
                    >
                      <option value="Development">Development</option>
                      <option value="Production">Production</option>
                    </select>
                  </Form.Group>
                </Col>
                <Col
                  xs={{ span: 1, offset: 8 }}
                  style={{ paddingTop: '0.5rem' }}
                >
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="refresh-tooltip-button">Refresh</Tooltip>
                    }
                  >
                    <BsArrowClockwise
                      className={classes.refreshIcon}
                      onClick={refreshSignalList}
                    />
                  </OverlayTrigger>
                </Col>
              </Row>
              {signalListDataRaw && signalListDataRaw.length === 0 && (
                <h4>No Record Exist</h4>
              )}
              {signalListDataRaw && signalListDataRaw.length > 0 && (
                <Fragment>
                  <Row>
                    <Modal
                      show={showModalForCloseSignal}
                      onHide={handleCloseModalForCloseForm}
                      backdrop="static"
                      keyboard={false}
                      centered
                      size="xl"
                    >
                      <Modal.Header>
                        <Modal.Title>
                          {selectedSignalTClose && (
                            <h4>
                              Signal ID - {selectedSignalTClose.id} For Closure{' '}
                            </h4>
                          )}
                        </Modal.Title>
                      </Modal.Header>
                      {selectedSignalTClose && (
                        <Modal.Body>
                          <Row>
                            <Col>
                              <Row>
                                <Form.Group className="mb-3">
                                  <Form.Label>
                                    <b>Close Price * </b>
                                  </Form.Label>
                                  <Form.Control
                                    id="txt_Close_Price"
                                    type="text"
                                    placeholder="Enter Close Price"
                                    value={signalDataToClose.close_price}
                                    onChange={onCloseFormValueChange}
                                  ></Form.Control>
                                </Form.Group>
                              </Row>
                              <Row>
                                <Form.Group className="mb-3" as={Row}>
                                  <Form.Label as="legend" column sm={4}>
                                    <b>Close Reason *</b>
                                  </Form.Label>
                                  <Col sm={6}>
                                    <Form.Check
                                      type="radio"
                                      label="SL"
                                      value="SL"
                                      defaultChecked={
                                        'SL' === signalDataToClose.closereason
                                      }
                                      name="rd_CloseReason"
                                      id="rd_CloseReason_SL_Id"
                                      onChange={onCloseFormValueChange}
                                    />
                                    <Form.Check
                                      type="radio"
                                      label="TP"
                                      value="TP"
                                      defaultChecked={
                                        'TP' === signalDataToClose.closereason
                                      }
                                      name="rd_CloseReason"
                                      id="rd_CloseReason_TP_Id"
                                      onChange={onCloseFormValueChange}
                                    />
                                    <Form.Check
                                      type="radio"
                                      label="buy_cond"
                                      value="buy_cond"
                                      defaultChecked={
                                        'buy_cond' ===
                                        signalDataToClose.closereason
                                      }
                                      name="rd_CloseReason"
                                      id="rd_CloseReason_buy_cond_Id"
                                      onChange={onCloseFormValueChange}
                                    />
                                    <Form.Check
                                      type="radio"
                                      label="sell_cond"
                                      value="sell_cond"
                                      defaultChecked={
                                        'sell_cond' ===
                                        signalDataToClose.closereason
                                      }
                                      name="rd_CloseReason"
                                      id="rd_CloseReason_sell_cond_Id"
                                      onChange={onCloseFormValueChange}
                                    />
                                    <Form.Check
                                      type="radio"
                                      label="ema_no_more_trending"
                                      value="ema_no_more_trending"
                                      defaultChecked={
                                        'ema_no_more_trending' ===
                                        signalDataToClose.closereason
                                      }
                                      name="rd_CloseReason"
                                      id="rd_CloseReason_ema_no_more_trending_Id"
                                      onChange={onCloseFormValueChange}
                                    />
                                    <Form.Check
                                      type="radio"
                                      label="max_minutes_in_trade"
                                      value="max_minutes_in_trade"
                                      defaultChecked={
                                        'max_minutes_in_trade' ===
                                        signalDataToClose.closereason
                                      }
                                      name="rd_CloseReason"
                                      id="rd_CloseReason_max_minutes_in_trade_Id"
                                      onChange={onCloseFormValueChange}
                                    />
                                  </Col>
                                </Form.Group>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formName01"
                                >
                                  <Form.Label>
                                    <b>Close At * </b>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    id="dt_Close_At"
                                    placeholder="DD-MM-YYYY HH:MM:SS"
                                    value={signalDataToClose.close_at}
                                    onChange={onCloseFormValueChange}
                                  ></Form.Control>
                                </Form.Group>
                              </Row>
                              <Row>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formName01"
                                >
                                  <Form.Label>
                                    <b>PNL * </b>
                                  </Form.Label>
                                  <Form.Control
                                    id="txt_pnl"
                                    type="text"
                                    placeholder="Enter PNL"
                                    value={signalDataToClose.pnl}
                                    onChange={onCloseFormValueChange}
                                  ></Form.Control>
                                </Form.Group>
                              </Row>
                            </Col>
                          </Row>
                        </Modal.Body>
                      )}
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={handleCloseModalForCloseForm}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="danger"
                          disabled={
                            signalDataToClose.close_price.length === 0 ||
                            signalDataToClose.close_at.length === 0 ||
                            signalDataToClose.pnl.length === 0 ||
                            signalDataToClose.closereason.length === 0
                          }
                          onClick={() => closeSignal(selectedSignalTClose.id)}
                        >
                          Proceed
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      show={showModalForViewSignalDetails}
                      onHide={handleCloseModal}
                      backdrop="static"
                      keyboard={false}
                      centered
                      size="xl"
                    >
                      <Modal.Header>
                        <Modal.Title>
                          <h4>Signal Details</h4>
                        </Modal.Title>
                      </Modal.Header>
                      {selectedSignalToView && (
                        <Modal.Body>
                          <Row>
                            <Col>
                              <Row>
                                <label>ID : {selectedSignalToView.id}</label>
                              </Row>
                              <Row>
                                <label>
                                  Client ID : {selectedSignalToView.clientid}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Signal ID : {selectedSignalToView.signalid}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Direction : {selectedSignalToView.direction}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Currency :{' '}
                                  {selectedSignalToView.underlying_currency}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Open Price : {selectedSignalToView.open_price}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Close Price :{' '}
                                  {selectedSignalToView.close_price}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Take Profit :{' '}
                                  {selectedSignalToView.take_profit}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Stop Loss : {selectedSignalToView.stop_loss}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Open At :{' '}
                                  <Moment format="DD-MM-YYYY HH:mm:ss">
                                    {selectedSignalToView.open_at}
                                  </Moment>
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Close At :{' '}
                                  {selectedSignalToView.close_at && (
                                    <Moment format="DD-MM-YYYY HH:mm:ss">
                                      {selectedSignalToView.close_at}
                                    </Moment>
                                  )}
                                </label>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <label>
                                  Signal Strength :{' '}
                                  {selectedSignalToView.signal_strength}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Rationale : {selectedSignalToView.rationale}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Opt :{' '}
                                  {selectedSignalToView.opt ? 'True' : 'False'}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Status : {selectedSignalToView.status}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Position Size :{' '}
                                  {selectedSignalToView.position_size}
                                </label>
                              </Row>
                              <Row>
                                <label>PNL : {selectedSignalToView.pnl}</label>
                              </Row>
                              <Row>
                                <label>
                                  Close Reason :{' '}
                                  {selectedSignalToView.closereason}
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Creation Time :{' '}
                                  <Moment format="DD-MM-YYYY HH:mm:ss">
                                    {selectedSignalToView.datetime_of_creation}
                                  </Moment>
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Update Time :{' '}
                                  <Moment format="DD-MM-YYYY HH:mm:ss">
                                    {selectedSignalToView.datetime_of_update}
                                  </Moment>
                                </label>
                              </Row>
                              <Row>
                                <label>
                                  Signal Mode :{' '}
                                  {selectedSignalToView.signal_mode}
                                </label>
                              </Row>
                            </Col>
                          </Row>
                        </Modal.Body>
                      )}
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Row>
                  <Row>
                    <Col xs={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="signalFilterCurrency"
                      >
                        <Form.Label>
                          <b>Currency</b>
                        </Form.Label>
                        <select
                          id="signalFilterCurrency"
                          className="form-select"
                          aria-label="Filter Option select "
                          value={currentFilterForSignalsCurrency}
                          onChange={onSignalFilterChange}
                        >
                          <option value="All">All</option>
                          {signalCurrencyList.length > 0 &&
                            signalCurrencyList.map((item, index) => (
                              <option value={item}>{item}</option>
                            ))}
                        </select>
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group className="mb-3" controlId="signalFilterMode">
                        <Form.Label>
                          <b>Mode</b>
                        </Form.Label>
                        <select
                          id="signalFilterMode"
                          className="form-select"
                          aria-label="Filter Option select "
                          value={currentFilterForSignalsMode}
                          onChange={onSignalFilterChange}
                        >
                          <option value="All">All</option>
                          <option value="M">M</option>
                          <option value="A">A</option>
                          <option value="N">N</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="signalFilterStatus"
                      >
                        <Form.Label>
                          <b>Status</b>
                        </Form.Label>
                        <select
                          id="signalFilterStatus"
                          className="form-select"
                          aria-label="Filter Option select "
                          value={currentFilterForSignalsStatus}
                          onChange={onSignalFilterChange}
                        >
                          <option value="All">All</option>
                          <option value="OPEN">Open</option>
                          <option value="CLOSE">Close</option>
                        </select>
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="signalFilterRecordCount"
                      >
                        <Form.Label>
                          <b>Record Count</b>
                        </Form.Label>
                        <select
                          id="signalFilterRecordCount"
                          className="form-select"
                          aria-label="Filter Option select "
                          value={currentFilterForSignalsCount}
                          onChange={onSignalFilterChange}
                        >
                          <option value="10">Latest 10 Records</option>
                          <option value="50">Latest 50 Records</option>
                          <option value="100">Latest 100 Records</option>
                          <option value="250">Latest 250 Records</option>
                        </select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <h4>
                      Total Count : <b> {signalList.length}</b>
                    </h4>
                    <br />
                    <br></br>
                  </Row>
                  <Row>
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      className={classes.tableSignal}
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Client ID</th>
                          <th>Signal ID</th>
                          <th>Currency</th>
                          <th>Open Price</th>
                          <th>Open At (UTC)</th>
                          <th>Create Time (UTC)</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {signalList.map((item, index) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.clientid}</td>
                            <td>{item.signalid}</td>
                            <td>{item.underlying_currency}</td>
                            <td>{item.open_price}</td>
                            <td>
                              <Moment format="DD-MM-YYYY HH:mm:ss">
                                {item.open_at}
                              </Moment>
                            </td>
                            <td>
                              {moment(
                                moment(item.datetime_of_creation).toDate(),
                              ).format('DD-MM-YYYY HH:mm:ss')}
                            </td>
                            <td
                              style={{
                                textAlign: 'left',  
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                size="2x"
                                className={classes.eyeIcon}
                                onClick={() => openSingalDetails(item)}
                              ></FontAwesomeIcon>
                              {(item.signal_mode === 'M' ||
                                item.signal_mode === 'N') &&
                                item.status !== 'CLOSE' && (
                                  <FontAwesomeIcon
                                    icon={faPowerOff}
                                    size="2x"
                                    style={{ paddingLeft: '6px' }}
                                    className={classes.closeIcon}
                                    onClick={() => openSingalFormToClose(item)}
                                  ></FontAwesomeIcon>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </Fragment>
              )}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}
