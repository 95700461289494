import { useRef, useContext } from 'react'
import AuthContext from '../../Store/AuthContext'
import { useHistory } from 'react-router-dom'
import { postData } from '../../utilities/dataProvider'
import configData from '../../configuration.json'
import classes from './AuthForm.module.css'

const AuthForm = () => {
  const history = useHistory()
  const userIdInputRef = useRef()
  const passwordInputRef = useRef()

  const authCTX = useContext(AuthContext)

  const loginHandler = async (event) => {
    event.preventDefault()
    const enteredUserId = userIdInputRef.current.value
    const enteredPassword = passwordInputRef.current.value

    if (enteredUserId && enteredPassword) {
      let loginData = {
        userId: enteredUserId,
        password: enteredPassword,
      }
      const LOGINURL = `${configData.API_BASE_URL}/auth/login`
      let resp = await postData(LOGINURL, loginData)
      if (resp && resp.authToken) {
        authCTX.login(resp.authToken, resp.userId)
        history.replace('/dashboard')
      }
    } else {
      alert('Invalid Credential !')
    }
  }

  return (
    <section className={classes.auth}>
      <h1>Login</h1>
      <form onSubmit={loginHandler}>
        <div className={classes.control}>
          <label htmlFor="userId">User Id</label>
          <input id="userId" required ref={userIdInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            required
            ref={passwordInputRef}
          />
        </div>
        <div className={classes.actions}>
          <button><b>Login</b></button>
        </div>
      </form>
    </section>
  )
}

export default AuthForm
